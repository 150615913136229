import { template as template_f859fba3b20945cf88920c354def5f6a } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { isTesting, macroCondition } from '@embroider/macros';
import FormField from '@qonto/ui-kit/components/form-fields/form-field';
import PowerSelectCustomsMultipleOption from '@qonto/ui-kit/components/power-select-customs/multiple/option';
import PowerSelectCustomsSearch from '@qonto/ui-kit/components/power-select-customs/search';
import PowerSelectCustomsTrigger from '@qonto/ui-kit/components/power-select-customs/trigger';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { t } from 'ember-intl';
import PowerSelectMultiple from 'ember-power-select/components/power-select-multiple';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import styles from './select-multiple.strict-module.css';
const DEBOUNCE_TIMEOUT = 200;
export default class ApprovalWorkflowFormSelectMultiple extends Component {
    @service
    intl;
    constructor(){
        super(...arguments);
        this.optionsTask.perform(null, false, true).catch(ignoreCancelation);
    }
    get selected() {
        return this.options.filter(({ key })=>this.args.selected?.includes(key));
    }
    get selectedValues() {
        return this.selected.map(({ value })=>value).join(', ');
    }
    optionsTask = restartableTask(async (query, withDebounce = true, initialLoad = false)=>{
        if (macroCondition(!isTesting())) {
            if (withDebounce) {
                await timeout(DEBOUNCE_TIMEOUT);
            }
        }
        return this.args.query(query, initialLoad);
    });
    get options() {
        return (this.optionsTask.last.value?.map((option)=>{
            let disabled = this.args.selectedAndDisabled?.includes(option.key);
            return {
                ...option,
                disabled
            };
        }) ?? []);
    }
    update = (selected)=>{
        // Due to the mapping of query results to options with `mapToOption` the options don't have the same identity after each select.
        // This function can receive two objects with same key when we want to deselect an option.
        // When two objects with the same key are received then they should not be selected.
        let result = selected?.reduce((acc, option)=>{
            let index = acc.findIndex((opt)=>opt.key === option.key);
            if (index >= 0) {
                acc.splice(index, 1);
            } else {
                acc.push(option);
            }
            return acc;
        }, []);
        // don't include 'selected' keys from other conditions
        result = result?.filter(({ key })=>!this.args.selectedAndDisabled?.includes(key));
        this.args.update(result);
    };
    getNoMatchesMessage = ()=>{
        return this.optionsTask.last.value?.length === 0 && !this.optionsTask.isRunning ? this.intl.t('labels.select_no_matches_message') : this.intl.t('labels.select_loading_message');
    };
    static{
        template_f859fba3b20945cf88920c354def5f6a(`
    <FormField ...attributes @messageLevel='error' @message={{@errorMessage}}>
      <:field as |field|>
        <PowerSelectMultiple
          @ariaDescribedBy={{field.messageId}}
          @triggerClass='power-select-customs-trigger block {{if @errorMessage "error"}}'
          @dropdownClass='power-select-custom'
          @triggerComponent={{component PowerSelectCustomsTrigger selected=this.selectedValues}}
          @beforeOptionsComponent={{component PowerSelectCustomsSearch}}
          @searchMessage={{t 'labels.select_search_message'}}
          @noMatchesMessage={{this.getNoMatchesMessage}}
          @loadingMessage={{t 'labels.select_loading_message'}}
          @searchPlaceholder={{t 'labels.search'}}
          @renderInPlace={{@renderInPlace}}
          @placeholder={{@placeholder}}
          @selected={{this.selected}}
          @options={{this.options}}
          @onClose={{@onClose}}
          @closeOnSelect={{false}}
          @allowClear={{true}}
          @searchEnabled={{true}}
          @searchField='value'
          @onChange={{this.update}}
          @onInput={{perform this.optionsTask}}
          @search={{this.handleSearch}}
          as |option select|
        >
          <PowerSelectCustomsMultipleOption @select={{select}} @key={{option.key}}>
            <span class={{styles.label}}>{{option.value}}</span>
          </PowerSelectCustomsMultipleOption>
        </PowerSelectMultiple>
      </:field>
    </FormField>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
