import { template as template_3a93a798ca0448a6b5eaffa1aa5e368e } from "@ember/template-compiler";
import Section from './section';
import styles from './sidebar-section.strict-module.css';
const template = template_3a93a798ca0448a6b5eaffa1aa5e368e(`
  <Section
    @approvalWorkflowState={{@approvalWorkflowState}}
    @requestType={{@requestType}}
    class={{styles.sidebar}}
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template;
